/*
 * @Author: DJIMNAIBEY Sidoine 
 * @Date: 2023-12-08 12:27:18 
 * @Last Modified by: DJIMNAIBEYE Sidoine
 * @Last Modified time: 2023-12-08 22:48:00
 */

import React, { useState, useContext, forwardRef, useImperativeHandle } from 'react'
import {
    useDisclosure,
    Box,
    Fade,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useColorModeValue,
    useSteps,
    ModalFooter,
    ButtonGroup,
    Button,
    Flex,
    Collapse,
    Center
} from '@chakra-ui/react'
import Datatable from '../Composants/datatable'
import { BsListCheck } from 'react-icons/bs'
import { tasks } from '../data'
import TaskForm from './TaskForm'
import { GlobalContext } from '../Context'
import StepperComposant from './StepperComposant'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Formulaire from '../Form'
import ModalNS from '../ModalNS'
import DrawerNS from '../DrawerNS'
import DatatableNS from '../TableNS/indejx'


/* The `MagicFormNS` component is a functional component in JavaScript that is created using the
`forwardRef` function. It takes in a set of props, including `schemaInits`, `champsInits`, `isOpen`,
`step`, and `onSubmit`. */
const MagicFormNS = forwardRef((props, ref) => {
    const {
        schemaInits,
        champsInits,
        isOpen,
        step,
        onSubmit,
        item,
        title,
        view,
        size
    } = props
    const [schema, setSchema] = useState({})
    const { register, handleSubmit, values, watch, setValue, reset, control, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(yup.object().shape(schema))
    });

    // Generate by ChatGPT
    /**
     * The function "recupererVariables" extracts variables enclosed in double curly braces from a
     * given string.
     * @param chaine - The parameter "chaine" is a string that represents a text or code snippet.
     * @returns an array of variables that are enclosed in double curly braces ({{}}) in the input
     * string.
     */
    function recupererVariables(chaine) {
        const regex = /\{\{([^}]+)\}\}/g;
        const matches = [];
        let match;

        while ((match = regex.exec(chaine)) !== null) {
            matches.push(match[1]);
        }

        return matches;
    }
    /**
     * The function `evaluerExpressionBoolenne` evaluates a boolean expression by replacing logical
     * operators with their JavaScript equivalents and then using `eval` to compute the result.
     * @param expression - The `expression` parameter is a string that represents a boolean expression.
     * It can contain logical operators such as "et" (and), "ou" (or), and "non" (not), as well as
     * variables enclosed in double curly braces (e.g., "{{variable}}").
     * @returns The function `evaluerExpressionBoolenne` returns either a boolean value if the
     * expression is successfully evaluated and the result is a boolean, or it returns `null` in case
     * of an error during evaluation.
     */
    function evaluerExpressionBoolenne(expression) {
        // Remplace les opérateurs logiques par leurs équivalents JavaScript
        // expression = expression.replace(/and/g, '&&').replace(/or/g, '||').replace(/not/g, '!');
        const variables = recupererVariables(expression)
        variables?.map(item => {
            // console.log("expression.replace(/\{\{{item}\}\}/g, watch(item))", expression.replace(/\{\{{item}\}\}/g, watch(item)))
            expression = expression.replace(new RegExp(`\{\{` + item + `\}\}`, 'g'), `'${watch(item)}'`)
        })
        // console.log("expression eeee", expression)
        try {
            // Évalue l'expression
            const resultat = eval(expression);

            // Vérifie si le résultat est un booléen
            if (typeof resultat === 'boolean') {
                return resultat;
            } else {
                throw new Error('L\'expression ne retourne pas un résultat booléen.');
            }
        } catch (erreur) {
            console.error('Erreur d\'évaluation de l\'expression :', erreur.message);
            return null; // En cas d'erreur, renvoie null
        }
    }


    /* The code block you provided is using the `React.useMemo()` hook to memoize the `champs`
    variable. */
    const champs = React.useMemo(() => {
        // console.log("Im in", schema)
        const tab = []
        champsInits(watch).map((liste, index) => {
            const tab2 = []
            const schemaTamp = {}
            liste?.filter((item, index2) => {
                // console.log("evaluerExpressionBoolenne(item?.display)",item?.display && evaluerExpressionBoolenne(item?.display))
                const newObject = {
                    ...item,
                    required: evaluerExpressionBoolenne(item?.requiredText),
                    isDisabled: evaluerExpressionBoolenne(item?.isDisabledText),
                    display: evaluerExpressionBoolenne(item?.displayText) ? 'block' : 'none'
                }

                tab2.push(newObject)
                // console.log("newObjec", newObject?.required, !newObject?.isDisabled, evaluerExpressionBoolenne(item?.displayText))
                if (newObject?.required && !newObject?.isDisabled && newObject.display !== 'none') {
                    schema[item?.name] = schemaInits[item?.name]
                }


            })
            tab.push(tab2)
        })
        // console.log("champsiInit", tab, schema)
        return tab
    }, [watch()])
    useImperativeHandle(ref, () => ({
        watch,
        reset
    }))

    // React.useEffect(() => {
    //     reset(item)
    // }, [])
    React.useEffect(() => {
        // console.log(evaluerExpressionBoolenne("!{{name}} && 'none'",))
    }, [champs])

    React.useEffect(() => {
        setSchema({})
    }, [schemaInits])
    return (
        <section>
            {/* <Collapse in={isOpen} animateOpacity > */}

            {view === 'drawer' ?
                <DrawerNS
                    isOpen={isOpen}
                    title={title}
                    buttons={step?.steps[step?.activeStep].buttons}
                    step={step}
                    onSubmit={handleSubmit(onSubmit)}
                    size={size ? size : "full"}
                    placement='right'
                >

                    <Formulaire
                        // onClose={onClose}
                        errors={errors}
                        register={register}
                        Controller={Controller}
                        control={control}
                        values={values}
                        setValue={setValue}
                        watch={watch}
                        onSubmit={onSubmit}
                        handleSubmit={handleSubmit}
                        champs={champs}
                    />

                </DrawerNS> : view === 'modal' ?
                    <ModalNS
                        isOpen={isOpen}
                        title={title}
                        buttons={step?.steps[step?.activeStep].buttons}
                        step={step}
                        onSubmit={handleSubmit(onSubmit)}
                        size={size ? size : "4xl"}
                        placement='right'
                    >

                        <Formulaire
                            // onClose={onClose}
                            errors={errors}
                            register={register}
                            Controller={Controller}
                            control={control}
                            values={values}
                            watch={watch}
                            setValue={setValue}
                            onSubmit={onSubmit}
                            handleSubmit={handleSubmit}
                            champs={champs}
                        />

                    </ModalNS> :


                    <Formulaire
                        // onClose={onClose}
                        errors={errors}
                        register={register}
                        Controller={Controller}
                        control={control}
                        values={values}
                        watch={watch}
                        setValue={setValue}
                        onSubmit={onSubmit}
                        handleSubmit={handleSubmit}
                        champs={champs}
                    />

            }
            {/* </Collapse> */}
        </section>
    )
}
)
export default MagicFormNS