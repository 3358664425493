import { Box, Container, Text, Stack, Button, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'

import { Link } from "react-router-dom";
import FooterSimple from '../Footer';
import Navbar from '../Header';
import { Banner } from './banner';
import { SimpleTable } from '../SimpleTable/table';
import { SimpleTableNS } from '../SimpleTable';
import { useDisclosure } from '@chakra-ui/react';
import { FormOrganisation } from '../Organisation';
import { GlobalContext } from '../Context';
import { ListeADonateur } from '../donateur/liste';
import { Description } from '../association/description';
import { ListeAssociation } from '../association/liste';
import { useNavigate } from 'react-router-dom';
import { Apropos } from './Apropos';
import { motion } from "framer-motion";
import { Team } from '../Team';
import { Carrers } from '../Careers';
import { Temoignage } from '../Testimonial';
import { Newletter } from '../Newsletter';
import { Showcase } from '../Showcase';
import Articles from '../Article';
import ArticleBlog from '../Article';
import { Partenaire } from '../partenaires';
import WithBackgroundImage from './Banner2';
import { Decission } from '../Decissions';
const Home = () => {
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const navigate = useNavigate()
  const { dataUser, logout, service, token } = React.useContext(GlobalContext)
  const [item, setItem] = React.useState({});
  const [financements, setFinancements] = React.useState([]);
  const [organisations, setOrganisations] = React.useState([]);
  const onGet = () => {
    service.get(token, "organisation/me", "", (resp) => {
      // alert(JSON.stringify(resp?.data?.data))
      setItem(resp?.data)
      // alert(JSON.stringify(resp?.data?.data))

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }

      }
    )
  }
  React.useEffect(() => {
    service.create(token, "filterfinancement", {
      secteurs: [],
      donateurs: [],
      pays: []

    }, (resp) => {
      setFinancements(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )
    service.create(token, "filterorganisation", {
      secteurs: [],
      donateurs: [],
      pays: []

    }, (resp) => {
      setOrganisations(resp?.data)

    },
      err => {
        if (err?.response?.status === 401) {
          logout()
          navigate('/login')
        }
        console.error(err?.response?.status === 401)
      }
    )


  }, []);

  return (


    < >
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 0
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <WithBackgroundImage />
      </motion.div>
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <Container
          my={{
            base: '5',
            lg: '5',
          }}
          w={{
            base: '100%',
            md: 'full',
          }}
          as="section"
          bg={'brand.primary.100'}
        >
          <Apropos />

        </Container>
      </motion.div>
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <Container
          my={{
            base: '5',
            lg: '5',
          }}
          w={{
            base: '100%',
            md: '90%',
          }}

        >
          <ArticleBlog />
        </Container>

      </motion.div>
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <Container
          my={{
            base: '5',
            lg: '5',
          }}
          w={{
            base: '100%',
            md: '90%',
          }}

          bg={'brand.primary.100'}
        >
          <Decission />

        </Container>
      </motion.div>
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <Container
          pb={{
            base: '5',
            lg: '5',
          }}
          // w={{
          //   base: '100%',
          //   md: '90%',
          // }}
          as="section"

        >

          <Team />
        </Container>

      </motion.div>
      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <Container
          pb={{
            base: '5',
            lg: '5',
          }}
          // minW={{
          //   base: '100%',
          //   md: '90%',
          // }}
          bg='brand.primary.100'
        >
          <Partenaire />

        </Container>
      </motion.div>
      {/* <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
         y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
      <Temoignage />
      </motion.div> */}

      <motion.div
        className="card"
        initial={{
          opacity: 0,
          // if odd index card,slide from right instead of left
          y: 100
        }}
        whileInView={{
          opacity: 1,
          y: 0, // Slide in to its original position
          transition: {
            duration: 0.75 // Animation duration
          }
        }}
        viewport={{ once: false }}
      >
        <Newletter />

      </motion.div>


    </>

  )
}

export default Home