import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { json, useNavigate } from 'react-router-dom';
import { UilFileShieldAlt } from '@iconscout/react-unicons'
import * as libUi from '../Composants/lib'
import ModalNS from '../ModalNS';
import Privilege from './privilege';
import DrawerNS from '../DrawerNS';
const Role = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const { isOpen: isOpenPrivilege, onClose: onClosePrivilege, onOpen: onOpenPrivilege } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [roles, setRoles] = useState([]);
    const [item, setItem] = useState({});
    const onGet = () => {
        service.create(token, "crud", {
            method: "GETALL",
            collection: "Role",
            id: "",
            body: {}
        }, (resp) => {
            setRoles(resp?.data?.data)

        },
            err => {
                if (err?.response?.status === 401) {
                    // logout()
                    // navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
    }
    const columns = [
        {
            title: 'Nom',
            field: 'libelle',

        },
        {
            title: 'Code',
            field: 'code',
        }
    ]
    useEffect(() => {

    }, [roles, item]);
    useEffect(() => {
        onGet()
    }, []);
    return (
        <>
            <Datatable
                icon={<BsListCheck />}
                title={"Liste de roles"}
                data={roles}
                onGet={onGet}
                columns={columns}
                setData={setRoles}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
                actions={[
                    {
                        icon: libUi.tableIcons.Privilege,
                        tooltip: 'Save User',
                        onClick: (event, rowData) => {
                            setItem(rowData)
                            onOpenPrivilege()
                        }
                    }
                ]}

            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormRole
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}
                setItem={setItem}


            />
            <FormPrivilege
                isOpen={isOpenPrivilege}
                privileges={item?.privileges}
                role={item}
                setRole={setItem}
                onClose={onClosePrivilege}
                onGet={onGet}
            />

        </>);
}

export default Role;

const FormRole = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item,
    setItem

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const { _id, tableData, url,updated_at, created_at,
            updated_by, created_by, ...rest } = data
        !_id ? service.create(token, "crud", {
            method: "POST",
            id: "",
            collection: "Role",
            body: rest

        }, (resp) => {
            onGet()
            funcs.current.reset({})
            setItem({})
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        ) :
            service.create(token, "crud", {
                method: "PUT",
                id: _id,
                collection: "Role",
                body: rest

            },
                (resp) => {
                    onGet()
                    funcs.current.reset({})
                    setItem({})
                    onClose()
                },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    console.error(err?.response?.status === 401)
                }
            )






    }
    const schemaInit = {
        libelle: yup.string().required("Champ libelle est obligatoire"),
        code: yup.string().required("Champ code est obligatoire").uppercase(),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    size: "md",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        setItem({})
                        onClose()
                    }
                }
            },
            {
                label: "Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?.id && 'none',
                    size: "md",
                    px: '10',
                    icon: <MdDelete />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: item?.id ? "Modifier" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    w: "full",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [

        [

            {
                name: "libelle",
                label: "Role",
                type: "text",
                placeholder: "Entrer le role",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "code",
                label: "Code",
                type: "text",
                placeholder: "Entrer le code",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""


            },
        ]
    ]
    useEffect(() => {
        console.log("item", item)
        funcs.current.reset(item)
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Edition des rôles "
            size='md'
            view="drawer"
        />
    </>);
}
const FormPrivilege = ({
    isOpen,
    onClose,
    onGet,
    privileges,
    role,
    setRole

}) => {

    const { service, token, logout } = useContext(GlobalContext)
    const [checkedPrivilege, setCheckedPrivilege] = useState([]);
    let navigate = useNavigate();
    const onSubmit = () => {
        const { _id, tableData, url,
            updated_at, created_at,
            updated_by, created_by,
            ...rest } = role
        // console.log("privilege", {privileges:checkedPrivilege.toString()})

        service.create(token, "crud", { method: "PUT", collection:"Role", id: _id, body: { ...rest, privileges: checkedPrivilege } }, (resp) => {
            onGet()
            setRole({})
            onClose()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
            }
        )






    }

    const buttons = [{
        label: "Quitter",
        propriety: {
            isDisabled: false,
            size: "md",
            icon: <MdClose />,
            onClick: () => {
                onClose()
                setRole({})
            }
        }
    },
    {
        label: "Supprimer",
        propriety: {
            isDisabled: false,
            display: 'none',
            size: "full",
            px: '15',
            icon: <MdDelete />,
            onClick: () => {

                onClose()
                setRole({})
            }
        }
    },
    {
        label: "Sauvegarder",
        propriety: {
            isDisabled: false,
            // type: "submit",
            size: "full",
            px: '15',
            icon: <MdSave />,
            onClick: () => {
                onSubmit()
            }
        }
    }]

    useEffect(() => {
    }, [checkedPrivilege]);
    return (<>
        <DrawerNS
            title="Gestion des privileges"
            buttons={buttons}
            isOpen={isOpen}
            step={null}
            size={'lg'}
            view="drawer"
        >
            <Privilege
                onSave={setCheckedPrivilege}
                privileges={privileges}
            />

        </DrawerNS>
    </>);
}
