import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const Chambre = ({
    typeChambre

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [chambres, setChambres] = useState(typeChambre?.chambres || []);
    const [item, setItem] = useState({});
    const [typeChambres, setTypeChambres] = useState([]);
    // const onGet = () => {
    //     service.create(token, "hcrud", {
    //         method: "GETALL",
    //         id: "",
    //         collection: "Chambre",
    //         body: {}
    //     }, (resp) => {
    //         setChambres(resp?.data?.data)
    //         // alert(JSON.stringify(resp?.data?.data))

    //     },
    //         err => {
    //             if (err?.response?.status === 401) {
    //                 logout()
    //                 navigate('/login')
    //             }
    //             console.error(err?.response?.status === 401)
    //         }
    //     )
    // }
    const columns = [
        {
            title: 'Nom',
            field: 'libelle',
            // render: rowData => <><ReactCountryFlag countryCode={rowData?.code}/> {rowData?.nom}</>
        },
        {
            title: 'Code',
            field: 'code',
        },
        // ,
        // {
        //     title: 'Type Chambre',
        //     field: 'type_chambre_id',
        //     lookup: typeChambres.reduce((acc, current, index) => {
        //         acc[current?._id] = current?.libelle;
        //         return acc;
        //     }, {})
        // }
    ]
    useEffect(() => {

    }, [chambres]);

    return (
        < >
            <Datatable
                icon={<BsListCheck />}
                title={`Categorie:  ${typeChambre?.libelle}`}
                data={chambres}
                onGet={()=>{}}
                columns={columns}
                setData={setChambres}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormChambre
                isOpen={isOpen}
                onClose={onClose}
                onGet={setChambres}
                item={item}
                typeChambre={typeChambre}
                chambres={chambres}


            />

        </>);
}

export default Chambre;

const FormChambre = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item,
    typeChambre,
    chambres

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const { _id,type_chambre, tableData, url, libelle,code, ...rest } = data

        let dataUpdate = [];
        const index = chambres?.map(e => e?.code)?.indexOf(code) 
        if (index!==-1) {
            dataUpdate = [...chambres]
            dataUpdate[index] = { libelle, code };
        }
        else {
            dataUpdate = [...chambres, { libelle, code, }]
        }

        service.create(token, "hcrud", {
            method: "PUT",
            id: typeChambre?._id,
            collection: "TypeChambre",
            body: { chambres: dataUpdate }
        }, (resp) => {
            onGet(resp?.data[0]?.chambres)
            funcs.current.reset({})
            onClose()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )





    }
    const schemaInit = {
        statut: yup.string().required("Champ status est obligatoire"),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    w: "full",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: "Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?.code && 'none',
                    w: "full",
                    mr: '2',
                    icon: <MdDelete />,
                    onClick: () => {
                        const index = chambres?.map(e => e?.code)?.indexOf(item?.code) 
                        const dataUpdate = [...chambres]
                        dataUpdate.splice(index, 1);
                        service.create(token, "hcrud", {
                            method: "PUT",
                            id: typeChambre?._id,
                            collection: "TypeChambre",
                            body: { chambres: dataUpdate }
                        }, (resp) => {
                            onGet(resp?.data[0]?.chambres)
                            funcs.current.reset({})
                            onClose()
                        },
                            err => {
                                if (err?.response?.status === 401) {
                                    logout()
                                    navigate('/login')
                                }
                                console.error(err?.response?.status === 401)
                            }
                        )
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: item?.code ? "Changer" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    w: "full",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [
        [{
            name: "type_chambre",
            label: "Type de Chambre",
            type: "text",
            placeholder: "Selectionner",
            requiredText: 'true',
            isDisabledText: 'true',
            displayText: "true",
            defaultValue: typeChambre?.libelle,
            helpText: ""


        }
        ],
        [
            {
                name: "libelle",
                label: "Nom ",
                type: "text",
                placeholder: "Entrer le libelle",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "code",
                label: "Code",
                type: "text",
                placeholder: "Entrer le code",
                requiredText: 'true',
                isDisabledText: !item?.code ? 'false': "true",
                displayText: "true",

                helpText: ""


            }
            

        ]
    ]
    useEffect(() => {
        console.log("item", item)
        funcs.current.reset(item)
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Formulaire "
            size='md'
            view="drawer"
        />
    </>);
}
