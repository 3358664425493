import { Button, ButtonGroup, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import StepperComposant from '../Task/StepperComposant'

function ModalNS({
    onSubmit,
    title,
    buttons,
    isOpen,
    step,
    children,
    size
}) {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    return (
        <section>
            <Modal isOpen={isOpen} size={size}  >
                <ModalOverlay
                    bg='none'
                    backdropFilter='auto'
                    backdropInvert='10%'
                    backdropBlur='2px'
                />
                <form
                    onSubmit={onSubmit}
                >
                    <ModalContent>
                        <ModalHeader
                            bg={useColorModeValue('gray.800', 'gray.800')}
                            color={"whiteAlpha.800"}
                            borderBottom={5}
                            borderStyle={'solid'}
                            borderColor={'brand.primary.100'}
                        >{title}</ModalHeader>
                        {/* <ModalCloseButton /> */}
                        <ModalBody>
                            {step?.steps?.length > 1?
                                <StepperComposant
                                    steps={step?.steps}
                                    activeStep={step?.activeStep}
                                    orientation={step?.orientation}
                                >
                                    {children}
                                </StepperComposant>: children }
                            

                        </ModalBody>
                        <ModalFooter
                            bg={useColorModeValue('gray.800', 'gray.800')}
                            color={"whiteAlpha.800"}
                            mt="2%"
                            borderTop={5}
                            borderStyle={'solid'}
                            borderColor={'brand.primary.100'}
                        >
                            <ButtonGroup mt="2%" w="100%"

                            >
                                <Flex w="100%" justifyContent="space-between">
                                    {buttons?.map(button => {
                                        return <Flex>
                                            <Button
                                                color={"whiteAlpha.900"}
                                                variant="solid"
                                                w="auto"
                                                leftIcon={button?.propriety?.icon}
                                                // mr="5%"
                                                {...button?.propriety}
                                                bgGradient="linear(to-r,brand.secondary, brand.primary.100)"
                                            >

                                                {isDesktop && button?.label}
                                            </Button>
                                        </Flex>
                                    })}
                                </Flex>
                            </ButtonGroup>
                        </ModalFooter>
                    </ModalContent>
                </form>
            </Modal>
        </section>
    )
}

export default ModalNS