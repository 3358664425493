import React, { useEffect, useContext, useState } from 'react';
import {
    Box, Text, Spacer, Button, Divider,
    useColorModeValue as mode
} from '@chakra-ui/react'
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdChevronRight,
    MdKeyboardArrowDown,
    MdAddBox,
    MdIndeterminateCheckBox,
    MdFolder,
    MdFolderOpen,
    MdInsertDriveFile
} from "react-icons/md";
import { GlobalContext } from '../Context';

const listePrivilegeIn = (privilege) => {
    if (!privilege.children) {
        return {
            value: privilege.code,
            label: privilege.label
        }
    }
    let listeTamp = []
    privilege.children.map(child => {
        listeTamp.push(listePrivilegeIn(child))
    })
    return {
        value: privilege.code,
        label: privilege.label,
        children: listeTamp

    }
}



const listePrivilegeOut = (privilege) => {
    if (!privilege.children) {
        return {
            code: privilege.value,
            libelle: privilege.label
        }
    }
    let listeTamp = []
    privilege.children.map(child => {
        listeTamp.push(listePrivilegeOut(child))
    })
    return {
        code: privilege.value,
        libelle: privilege.label,
        enfants: listeTamp

    }

}
const Privilege = ({
    onSave,
    privileges,
}) => {
    const [checked, setChecked] = useState([])
    const [expanded, setExpanded] = useState([])
    const [nodes, setNodes] = useState([])
    const context = useContext(GlobalContext)
    const { token, service } = context

    useEffect(() => {
        // console.log(checked)
        onSave(checked)
    }, [checked])

    useEffect(() => {
        service.getAll(token, "privileges", (resp) => {
            const data = resp.data
            setNodes(data)
            if (privileges.includes("all")){
                setChecked(data.map(item=>item?.value))
            }else{
                setChecked(privileges)
            }
        },
            (error) => {
                console.error(error)
            });
        
        
    }, [privileges])

    return (
        <section> 
            <Divider my="4" />
            <Box
                bg={{
                    md: mode('white', 'gray.700'),
                }}
            >
                <CheckboxTree
                    nodes={nodes}
                    checked={checked}
                    expanded={expanded}
                    onCheck={checked => setChecked(checked)}
                    onExpand={expanded => setExpanded(expanded)}
                    icons={{
                        check: <MdCheckBox className="rct-icon rct-icon-check" style={{ color: "#b28a42", fontWeight: "bold" }} />,
                        uncheck: <MdCheckBoxOutlineBlank className="rct-icon rct-icon-uncheck" style={{ color: "#b28a42" }} />,
                        halfCheck: (
                            <MdIndeterminateCheckBox className="rct-icon rct-icon-half-check" style={{ color: "#b28a42", fontWeight: "bold" }} />
                        ),
                        expandClose: (
                            <MdChevronRight className="rct-icon rct-icon-expand-close" style={{ color: "#b28a42" }} />
                        ),
                        expandOpen: (
                            <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" style={{ color: "#b28a42" }} />
                        ),
                        expandAll: <MdAddBox className="rct-icon rct-icon-expand-all" style={{ color: "#b28a42" }} />,
                        collapseAll: (
                            <MdIndeterminateCheckBox className="rct-icon rct-icon-collapse-all" style={{ color: "#b28a42" }} />
                        ),
                        parentClose: <MdFolder className="rct-icon rct-icon-parent-close" style={{ color: "#b28a42" }} />,
                        parentOpen: <MdFolderOpen className="rct-icon rct-icon-parent-open" style={{ color: "#b28a42" }} />,
                        leaf: <MdInsertDriveFile className="rct-icon rct-icon-leaf-close" style={{ color: "#b28a42" }} />
                    }}
                />
            </Box>

        </section>
    );
}
export default Privilege