import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React from 'react'
import { MultiSelect } from 'react-multi-select-component'

export const MultiSelectForm = React.forwardRef((props, ref) => {
    const { name, label, helpText,liste, defaultValue, placeholder, required, errors, control, Controller, direction, display } = props

    return (
        <>
            <FormControl mt="2%" isInvalid={errors[name]} display={display} w={{base: "full" ,md:"full"}}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required ? `${label}*` : `${label}`}
                </FormLabel>

                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref } }) => (
                        <MultiSelect
                            options={liste}
                            value={value}
                            onChange={onChange}
                            labelledBy={placeholder}
                        />
                    )}
                />
                <FormErrorMessage>
                    {errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
MultiSelectForm.displayName = 'MultiSelectForm'