import { Center, Container, Heading, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import Pnud from '../../Media/images/PNUD.png'
import Ue from '../../Media/images/UE.png'
import ceeac from '../../Media/images/ceeac-logo.jpg'
// import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Partenaire = () => {
  const partenaires = [
    <Center
      bg="bg.surface"
      py={{ base: '4', md: '4' }}
      boxShadow="sm"
      borderRadius="lg"
      mx={{ base: '4', md: '8' }}
    >
      <Image src={Pnud} h={{ base: '8', md: '20' }} w="auto" />
    </Center>
    ,
    <Center
      bg="bg.surface"
      py={{ base: '4', md: '4' }}
      boxShadow="sm"
      borderRadius="lg"
      mx={{ base: '4', md: '8' }}
    >
      <Image src={Ue} h={{ base: '8', md: '20' }} w="auto" />
    </Center>
    ,
    <Center
      bg="bg.surface"
      py={{ base: '4', md: '4' }}
      boxShadow="sm"
      borderRadius="lg"
      mx={{ base: '4', md: '8' }}
    >
      <Image src={ceeac} h={{ base: '8', md: '20' }} w="auto" />
    </Center>
    ,
    <Center
      bg="bg.surface"
      py={{ base: '4', md: '4' }}
      boxShadow="sm"
      borderRadius="lg"
      mx={{ base: '4', md: '8' }}
    >
      <Image src={ceeac} h={{ base: '8', md: '20' }} w="auto" />
    </Center>
    ,
    <Center
      bg="bg.surface"
      py={{ base: '4', md: '4' }}
      boxShadow="sm"
      borderRadius="lg"
      mx={{ base: '4', md: '8' }}
    >
      <Image src={ceeac} h={{ base: '8', md: '20' }} w="auto" />
    </Center>

  ]
  return (
    <Container py={{ base: '4', md: '8' }}>
      <Stack spacing="8">
        <Heading
          // as="h1"
          size={{ base: "sm", md: "md" }}
          color={"brand.secondary"}
          mt="5"
          fontWeight="extrabold"
          letterSpacing="tight"
          textAlign='center'
        >
          Nos partenaires
        </Heading>
        <SimpleSlider items={partenaires}
          settingsIn={{
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            speed: 500,
          }}
        />
      </Stack>
    </Container>
  )
}



export function SimpleSlider({ items, settingsIn }) {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    ...settingsIn,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],

  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {items.map(item => {
          return (
            <div>
              {item}
            </div>
          )
        })}

      </Slider>
    </div>
  );
}