import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const typeSejours = [
    {
        code: "NT",
        type: "Nuitée",
        check_in: "12:00",
        duree: 24
    },
    {
        code: "DNT",
        type: "Demi Nuitée",
        check_in: "18:00",
        duree: 14
    },
    {
        code: "DJN",
        type: "Demi Journée",
        check_in: "08:00",
        duree: 10
    },
]
const Tarification = ({
    typeChambre,
    onGet

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [item, setItem] = useState({});
    const [tarifications, setTarifications] = useState([]);

    const columns = [
        {
            title: 'Type de Sejour',
            field: 'code',
            lookup: typeSejours.reduce((acc, current, index) => {
                acc[current?.code] = current?.type;
                return acc;
            }, {})
        },
        {
            title: 'Prix',
            field: 'prix',
        },
        {
            title: 'Check In',
            field: 'check_in',
        },
        {
            title: 'Duree',
            field: 'duree',
        },
       
    ]
    useEffect(() => {
        setTarifications(typeChambre?.tarifications?.map(item=>({...item, ...typeSejours?.find(type=>type.code===item?.code)})) || [])

    }, []);
    return (
        < >
            <Datatable
                icon={<BsListCheck />}
                title={"Liste de chambres"}
                data={tarifications}
                onGet={onGet}
                columns={columns}
                setData={setTarifications}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={typeSejours.filter(type=>!tarifications.map(tar=>tar.code).includes(type.code)).length}
                canEdit={true}
            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormTarification
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}
                tarifications={tarifications}
                typeSejours={typeSejours}
                typeChambre={typeChambre}


            />

        </>);
}

export default Tarification;

const FormTarification = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item,
    tarifications,
    typeSejours,
    typeChambre

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {
    
        const { tableData, url, prix, code, ...rest } = data
        let dataUpdate = [];
        const index = tarifications?.map(e => e?.code)?.indexOf(code) 
        if (index!==-1) {
            dataUpdate = [...tarifications]
            dataUpdate[index] = { code:code, prix:prix };
        }
        else {
            dataUpdate = [...tarifications, { code, prix }]
        }

        service.create(token, "hcrud", {
            method: "PUT",
            id: typeChambre?._id,
            collection: "TypeChambre",
            body: { tarifications: dataUpdate }
        }, (resp) => {
            onGet()
            funcs.current.reset({})
            onClose()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )






    }
    const schemaInit = {
        prix: yup.number().required("Champ prix est obligatoire"),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    w: "full",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: "Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?.code && 'none',
                    w: "full",
                    mr: '2',
                    icon: <MdDelete />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },

            {
                label: item?.code ? "Changer" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    w: "full",
                    mr: '2',
                    icon: item?.code ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [
        [
            {
                name: "code",
                label: "Type de  Sejour",
                type: "select",
                // placeholder: "Selectionner",
                requiredText: 'true',
                isDisabledText: item?.code ? "true" : "false",
                displayText: "true",
                liste: typeSejours.filter(type=>!tarifications.map(tar=>tar.code).includes(type.code))
                ?.map(item => (
                    {
                        key: item?.code, value: item?.type
                    }
                )),
                helpText: "",
                defaultValue: item?.code


            }


        ]
        ,
        [
            {
                name: "check_in",
                label: "Check In ",
                type: "time",
                placeholder: "Entrer le libelle",
                requiredText: 'false',
                isDisabledText: 'true',
                displayText: "true",
                helpText: "",
                defaultValue: typeSejours.find(item => item?.code === funcs.current?.watch("code"))?.check_in

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "duree",
                label: "Duree du Sejour",
                type: "text",
                placeholder: "Entrer le code",
                requiredText: 'true',
                isDisabledText: 'true',
                displayText: "true",
                helpText: "",
                defaultValue: typeSejours.find(item => item?.code === funcs.current?.watch("code"))?.duree


            }


        ],
        [{
            name: "prix",
            label: "Prix du Sejour",
            type: "number",
            placeholder: "Entrer le code",
            requiredText: 'true',
            isDisabledText: 'false',
            displayText: "true",
            helpText: ""


        }]
    ]
    useEffect(() => {
        funcs.current.reset({ ...typeSejours?.find(type => type?.code === item?.code) , ...item})
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Formulaire "
            size='md'
            view="drawer"
        />
    </>);
}
