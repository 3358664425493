import {
    Box,
    Button,
    Heading,
    Img,
    Stack,
    Text,
    Accordion,
    useColorModeValue as mode,
    Card,
    CardHeader,
    CardBody,
    StackDivider,
    Container,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'
import { HiPlay } from 'react-icons/hi'
import { Link } from "react-router-dom";
import bg2 from './../../Media/images/PR_CC.jpeg'
import { useTranslation } from 'react-i18next';
import { FacebookShare } from 'react-share-kit'
import FormDecision from './Form';
import { SimpleSlider } from '../partenaires';
export function Decission() {
    const [expanded, setExpanded] = React.useState(0);
    const { t } = useTranslation()

    return (
        <Box as="section" pb="10"
            w={{
                base: '100%',
                md: '100%',
            }}
        >
            <Box maxW={{ base: 'auto', md: 'full' }} px={{ base: '1', md: '4' }}>
                <Stack
                    direction={{ base: 'column', lg: 'row' }}
                    spacing={{ base: '3rem', lg: '2rem' }}
                    mt="8"
                    align={{ lg: 'center' }}
                    justify="space-between"
                >
                    <Box h={{ base: 'auto', lg: '100%' }} w={{base: "100%" , md:"30%" }} bg="white">
                        <FormDecision />
                    </Box>
                    <Box flex="1"  w={{base: "100%" , md:"70%" }}>
                        <Heading
                            // as="h1"
                            size={{ base: "sm", md: "md" }}
                            color={"brand.secondary"}
                            mt="5"
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign='center'
                        >
                            Les décisions récentes
                        </Heading>
                       <DecisionsRecente/>

                    </Box>

                </Stack>
            </Box>
        </Box>
    )
}


const dataList = [
    {
        id: 1,
        reference: 'DCC24-00001 du 12 Octobre 2024',
        type:' Décisions ordinaires (DCC)',
        requerant: ' Jahn Doe',
        objet: " Recours pour « violation des droits humains et vice de procédure »",
    },
    {
        id: 2,
        reference: 'DCC24-00002 du 12 Octobre 2024',
        type:' Décisions ordinaires (DCC)',
        requerant: ' Taylor Doe',
        objet: " Sollicite l'assistance de la Cour afin d'obtenir une réduction de sa peine ",
    },
    {
        id: 3,
        reference: 'DCC24-00003 du 12 Novembre 2024',
        type:' Décisions ordinaires (DCC)',
        requerant: ' Président de la République ; Célestin Nounagnon HOUNSOU ',
        objet: " Recours en inconstitutionnalité de l’arrêt n°047/CJ-DF, rendu le 07 avril 2023 par la chambre judici... »",
    },
    {
        id: 6,
        reference: 'DCC24-00004 du 12 Octobre 2024',
        type:' Décisions ordinaires (DCC)',
        requerant: ' John Doe',
        objet: "Recours pour « violation des droits humains et vice de procédure »",
    },
    {
        id: 5,
        reference: 'DCC24-00005 du 12 Octobre 2024',
        type:' Décisions ordinaires (DCC)',
        requerant: ' John Doe',
        objet: " Recours pour « violation des droits humains et vice de procédure »",
    },
    {
        id: 4,
        reference: 'DCC24-00006 du 12 Octobre 2024',
        type:' Décisions ordinaires (DCC)',
        requerant: ' John Doe',
        objet: " Recours pour « violation des droits humains et vice de procédure »",
     
    }
];

const DecisionsRecente = () => {
    const decisions = dataList.map((item) => {
        return (

                <Card  w={{base: "100%" , md:"xs" }}>
                    <CardHeader bg={"brand.secondary"} color="whiteAlpha.900">
                        <Text
                            fontWeight="extrabold"
                            letterSpacing="tight"
                            textAlign='center'
                            size='md'> {item?.reference} </Text>
                    </CardHeader>

                    <CardBody>
                        <Stack divider={<StackDivider />} spacing='4'>
                            <Box>
                                <Text size='xs' textTransform='uppercase'>
                                    Type de décision :
                                </Text>
                                <Text pt='2' fontSize='sm'>
                                    {item?.type}
                                </Text>
                            </Box>
                            <Box>
                                <Text size='xs' textTransform='uppercase'>
                                    Requérant :
                                </Text>
                                <Text pt='2' fontSize='sm'>
                                   {item?.requerant}
                                </Text>
                            </Box>
                            <Box>
                                <Text size='xs' textTransform='uppercase'>
                                    Objet de la requête :
                                </Text>
                                <Text pt='2' fontSize='sm'>
                                   {item?.objet}
                                </Text>
                            </Box>
                        </Stack>
                    </CardBody>
                </Card>
            // </Box>
        );
    })
    return (
        <Container w={{ base: 'auto', md: '100%' }} p={{ base: 5, md: 10 }}>
            <SimpleSlider items={decisions} settingsIn={{
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,

            }} />
        </Container>
    );
};