export const members = [
  {
    role: 'Co-Founder / CEO',
    image:
      'https://conseil-constitutionnel.td/static/assets/img/_MG_5182.JPG',
    name: 'Camila West',

    description: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
  },
  {
    role: 'Co-Founder / CTO',
    image:"https://conseil-constitutionnel.td/static/assets/img/_MG_5189.JPG" ,
       name: 'Mark Linhsorg',

    description: 'Habitant morbi tristique senectus et netus et malesuada fames vestibulum.',
  },
  {
    role: 'Marketing Manager',
    image:
      'https://conseil-constitutionnel.td/static/assets/img/_MG_5187.JPG',
    name: 'Kim Yung',

    description: 'Quis risus sed vulputate odio ut enim blandit volutpat. Amet cursus sit amet.',
  },
  {
    role: 'Manager, Business Relations',
    image:
      'https://conseil-constitutionnel.td/static/assets/img/_MG_5186.JPG',
    name: 'Morgan Adebayo',

    description: 'Consectetur libero id faucibus nisl tincidunt eget nullam fringilla urna.',
  },
  {
    role: 'Chief Operating Officer',
    image:
      'https://conseil-constitutionnel.td/static/assets/img/_MG_5193.JPG',
    name: 'Bimbo Akintola',

    description: 'Mi eget mauris pharetra et ultrices neque ornare aenean massa eget egestas.',
  },
  {
    role: 'Head of Human Resources',
    image:
      'https://conseil-constitutionnel.td/static/assets/img/_MG_5192.JPG',
    name: 'Yasmine Jones',

    description: 'Diam maecenas sed enim ut sem viverra aliquet eget magna ac placerat.',
  },
]
