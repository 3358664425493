import { Checkbox, CheckboxGroup, FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack } from '@chakra-ui/react'
import React from 'react'
import { CheckboxCard, CheckboxCardGroup } from '../../Composants/CheckboxCardGroup'

export const CheckBoxForm = React.forwardRef((props, ref) => {
    const { name, label, helpText, liste, defaultValue, placeholder, required, errors, control, Controller, direction, display, ...rest } = props

    return (
        <>
            <FormControl mt={5} isInvalid={errors[name]} display={display} w={{base: "full" ,md:"full"}}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required ? `${label}*` : `${label}`}
                </FormLabel>

                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref } }) => (
                        <CheckboxCardGroup
                            placeholder={placeholder}
                            onChange={onChange}
                            value={value}


                        >
                            <Stack spacing={[1, 5]} direction={direction}>
                                {liste.map((item, index) => {
                                    return (
                                        <CheckboxCard id={index} value={item.key} colorScheme='yellow' >{item.value}</CheckboxCard>
                                    )
                                })
                                }
                            </Stack>
                        </CheckboxCardGroup>
                    )}
                />


                <FormErrorMessage>
                    {errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
CheckBoxForm.displayName = 'CheckBoxForm'