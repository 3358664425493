import {
    Avatar,
    AvatarGroup,
    Box,
    Center,
    DarkMode,
    Flex,
    Heading,
    HStack,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue as mode,
    ScaleFade,
    useDisclosure
} from '@chakra-ui/react'
import * as React from 'react'
import LoginForm from './LoginForm'
import LogoSA, { LogoSAVert } from '../Header/LogoSA'

const Login = () => {
    const { isOpen, onOpen, onToggle } = useDisclosure()
    const loginPaddingBreakpoint = useBreakpointValue(
        {
            base: "0",
            md: "5"
        }
    )
    const loginsizeBreakpoint = useBreakpointValue(
        {
            xl: "lg",
            lg: "xs",
            md: "xs",
            sm: "lg",
            base: "xs",
        }
    )
    // <Flex
    //     minH={{
    //         base: 'auto',
    //         md: '100vh',
    //     }}
    //     bgGradient={useBreakpointValue({
    //         md: mode(
    //             'linear(to-r, brand.primary.100 50%, white 50%)',
    //             'linear(to-r, brand.primary.100 50%, gray.900 50%)',
    //         ),
    //     })}
    //     width="100%"
    // >
    //     <Flex px="auto" >
    //         {/* <DarkMode> */}
    //             <Flex
    //                 direction="column"
    //                 px={{
    //                     base: '4',
    //                     md: '8',
    //                 }}
    //                 height="full"
    //             // color="on-accent"
    //             >
    //                 <Flex align="center" h="24">
    //                     {/* <LogoSA/> */}
    //                 </Flex>
    //                 <Center flex="1" >
    //                     <LoginForm
    //                     />
    //                 </Center>
    //                 <Flex align="center" h="24">
    //                     <Text color="white" fontSize="sm">
    //                         © 2022 Naibeye Digid. All rights reserved.
    //                     </Text>
    //                 </Flex>
    //             </Flex>
    //         {/* </DarkMode> */}
    //         {/* </Box> */}

    //     </Flex>
    // </Flex>
    return (
        <Box bgGradient={{ sm: 'linear(to-bl, brand.primary.100, black)' }} >
            <Box p={{ base: 'auto', md: '10%' }} h="95vh">
                <Box
                    m={'auto'}
                    w={{ base: "full", md: "600px" }}
                    // pb={'4'}

                    // borderWidth={2}
                    // borderColor={"brand.primary.100"}
                    // rounded="2xl"
                    bg="white"
                    shadow={{
                        md: 'lg',
                    }}
                    // onMouseEnter={onOpen}
                    // onMouseLeave={onToggle}
                    borderBottomWidth={4}
                    borderBottomColor={"brand.primary.100"}
                >
                    <Box
                        align="center"
                        color={"brand.primary.100"}
                        bg={"white"}
                    // roundedTop="2xl"
                    // borderBottomWidth={5}
                    // borderBottomColor={"brand.primary.100"}
                    w="100%"

                    >

                        <Center>


                            <LogoSAVert
                                title="Connexion"
                            />

                        </Center>
                        <Text px={5}>Bienvenue sur le site officiel du Conseil Constitutionnel. C'est avec grand plaisir que nous vous accueillons dans cet espace dédié à la défense de l'État de droit et à la protection des principes fondamentaux de notre Constitution.</Text>
                    </Box>
                    <Box


                        align="center"
                    >
                        {!isOpen &&

                            <ScaleFade initialScale={0.1} in={!isOpen}>
                                <Box pb={'10'} p={loginPaddingBreakpoint}
                                    borderBottomWidth={4} borderBottomColor={"brand.primary.100"}
                                    px={4}
                                >
                                    <LoginForm />
                                </Box>
                            </ScaleFade>

                        }
                        {isOpen &&
                            <ScaleFade initialScale={0.1} in={isOpen}>
                                {/* <Flex spacing={4} mx={"20%"} justifyContent={"space-between"}

                                > */}
                                {/* <Box
                                        p={1}
                                        // m={'auto'}
                                        // display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Logo_ />
                                    </Box> */}
                                <Text
                                    as="h1"
                                    size={loginsizeBreakpoint}
                                    letterSpacing="tight"
                                    color={"brand.orange.100"}
                                    align={"center"}

                                >
                                    Login
                                </Text>
                                {/* </Flex> */}

                            </ScaleFade>
                        }
                    </Box>
                </Box>



            </Box>
            <Flex align="center" h="24">
                <Text color="white" fontSize="sm">
                    © 2024 site officiel du Conseil Constitutionnel . All rights reserved.
                </Text>
            </Flex>

        </Box>
    )
}
export default Login