import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { json, useNavigate } from 'react-router-dom';
import { UilFileShieldAlt } from '@iconscout/react-unicons'
import * as libUi from '../Composants/lib'
import ModalNS from '../ModalNS';
import Privilege from './privilege';
import DrawerNS from '../DrawerNS';
import Tarification from './Tarification';
import Chambre from './Chambre';
const TypeChambre = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const { isOpen: isOpenTarification, onClose: onCloseTarification, onOpen: onOpenTarification } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [typeChambres, setTypeChambres] = useState([]);
    const [item, setItem] = useState({});
    const onGet = () => {
        service.create(token, "hcrud", {
            method: "GETALL",
            collection: "TypeChambre",
            id: "",
            body: {}
        }, (resp) => {
            setTypeChambres(resp?.data?.data)

        },
            err => {
                if (err?.response?.status === 401) {
                    // logout()
                    // navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
    }
    const columns = [
        {
            title: 'Libelle',
            field: 'libelle',

        },
        {
            title: 'Code',
            field: 'code',
        }
    ]
    useEffect(() => {

    }, [typeChambres, item]);
    useEffect(() => {
        onGet()
    }, []);
    return (
        <>
            <Datatable
                icon={<BsListCheck />}
                title={"Type Chambres"}
                data={typeChambres}
                onGet={onGet}
                columns={columns}
                setData={setTypeChambres}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
                detailPanel={[
                    {
                        icon: libUi.tableIcons.Price,
                        render: rowData => {
                            return (
                                <Tarification
                                    typeChambre={rowData}
                                    onGet={onGet}
                                />
                            )
                        }
                    },
                    {
                        icon: libUi.tableIcons.Chambre,
                        tooltip: 'Show Surname',
                        render: rowData => {
                            return (
                                <Chambre
                                typeChambre={rowData}
                                />
                            )
                        },
                    }
                ]}
            // actions={[
            //     {
            //         icon: libUi.tableIcons.Privilege,
            //         tooltip: 'Tarification',
            //         onClick: (event, rowData) => {
            //             setItem(rowData)
            //             onOpenTarification()
            //         }
            //     }
            // ]}

            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormTypeChambre
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}
                setItem={setItem}


            />


        </>);
}

export default TypeChambre;

const FormTypeChambre = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item,
    setItem

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const { _id, tableData, url, updated_at, created_at,
            updated_by, created_by, ...rest } = data
        !_id ? service.create(token, "hcrud", {
            method: "POST",
            id: "",
            collection: "TypeChambre",
            body: rest

        }, (resp) => {
            onGet()
            funcs.current.reset({})
            setItem({})
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        ) :
            service.create(token, "hcrud", {
                method: "PUT",
                id: _id,
                collection: "TypeChambre",
                body: rest

            },
                (resp) => {
                    onGet()
                    funcs.current.reset({})
                    setItem({})
                    onClose()
                },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    console.error(err?.response?.status === 401)
                }
            )






    }
    const schemaInit = {
        libelle: yup.string().required("Champ libelle est obligatoire"),
        photo: yup.string().required("Champ libelle est obligatoire"),
        code: yup.string().required("Champ code est obligatoire").uppercase(),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    w: "full",
                    mr: '2',
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        setItem({})
                        onClose()
                    }
                }
            },
            {
                label: "Supprimer",
                propriety: {
                    isDisabled: false,
                    display: !item?._id && 'none',
                    w: "full",
                    mr: '2',
                    icon: <MdDelete />,
                    onClick: () => {
                        service.create(token, "hcrud", {
                            method: "DELETE",
                            id: item?._id,
                            collection: "TypeChambre",
                            body: { }
                        }, (resp) => {
                            onGet()
                            funcs.current.reset({})
                            onClose()
                        },
                            err => {
                                if (err?.response?.status === 401) {
                                    logout()
                                    navigate('/login')
                                }
                                console.error(err?.response?.status === 401)
                            }
                        )
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },
            {
                label: item?._id ? "Modifier" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    w: "full",
                    mr: '2',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [

        [
            {
                name: "photo",
                label: "Photo d'illustration",
                type: "image",
                placeholder: "Entrer le mail",
                requiredText: 'true',
                isDisabledText: 'true',
                displayText: "true",
                helpText: "",
                boxSize: "250px"

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            }]
        ,
        [

            {
                name: "libelle",
                label: "Libelle",
                type: "text",
                placeholder: "Entrer le libelle",

                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "code",
                label: "Code",
                type: "text",
                placeholder: "Entrer le code",
                requiredText: 'true',
                isDisabledText: !item?.code ? 'false': "true",
                displayText: "true",
                helpText: ""


            },
        ]
    ]
    useEffect(() => {
        console.log("item", item)
        funcs.current.reset(item)
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Edition des Types de chambres "
            size='md'
            view="drawer"
        />
    </>);
}
