import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Stack,
    StackDivider,
    Text,
} from '@chakra-ui/react'
import {
    FiBookmark,
    FiClock,
    FiGrid,
    FiHelpCircle,
    FiMoreVertical,
    FiPieChart,
    FiSearch,
    FiSettings,
    FiUser,
} from 'react-icons/fi'
import { DocumentCollapse } from './DocumentCollapse'
//   import { Logo } from './Logo'
import { SidebarButton } from './SidebarButton'
import { useNavigate } from 'react-router-dom'
import { Each } from '../../../util'
import { BiHome } from 'react-icons/bi'
import { FaLockOpen } from 'react-icons/fa'
import { NavButton } from '../NavButton'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

const MenuPrincipalBody = ({ menuData, user, logout }) => {
    const navigate = useNavigate()

    return (
        <Flex as="section" minH="100vh"
            bgGradient={'linear(to-l, brand.primary.100, brand.secondary)'}
            color={"white"}
        >
            <Stack
                flex="1"
                maxW={{ base: 'full', sm: 'xs' }}
                py={{ base: '6', sm: '8' }}
                px={{ base: '4', sm: '6' }}
                // color="white"
                borderLeftWidth="1px"
                borderColor={"brand.secondary"}
                justifyContent="space-between"
            >
                <Stack spacing="8" pt={50}>
                    {/* <Logo alignSelf="start" /> */}
                    {/* <InputGroup>
                        <InputLeftElement>
                            <Icon as={FiSearch} color="fg.accent.muted" fontSize="lg" />
                        </InputLeftElement>
                        <Input placeholder="Search" variant="filled.accent" />
                    </InputGroup> */}
                    <Stack spacing="1">
                        {menuData?.map(item => item?.children.length ? <DocumentCollapse item={item} /> :
                            <SidebarButton leftIcon={<Icon as={item?.icon}
                            />}><Link href={item?.url}> {item?.label} </Link></SidebarButton>)}
                    </Stack>
                </Stack>
                <Stack spacing="4" divider={<StackDivider borderColor="bg.accent.subtle" />}>
                    <Box />
                    <Stack spacing="1">
                        {/* <SidebarButton leftIcon={<FiHelpCircle />}>Help Center</SidebarButton> */}
                        <SidebarButton leftIcon={<FiSettings />}><Link href={"administration"}>Settings</Link></SidebarButton>
                    </Stack>
                    {user && <HStack spacing="3" justify="space-between">
                        <HStack spacing="3">
                            <Avatar boxSize="10" 
                             name={user?.photo_full ? `${ user?.photo_full?.alt}` :  user?.mail}
                             src={`${ user?.photo_full?.url}` || "https://i.pravatar.cc/300"}
                            />
                            <Box>
                                <Text textStyle="sm" fontWeight="medium">
                                    {`${user?.nom}`}
                                </Text>
                                <Text textStyle="sm" color="fg.accent.muted">
                                    {`${user?.mail}`}
                                </Text>
                            </Box>

                        </HStack>
                        <IconButton variant="tertiary.accent" icon={<FiMoreVertical />} aria-label="Open Menu" />
                    </HStack>}
                    {user ? <HStack as={Link} spacing={5} color={'white'} onClick={() => { logout(); navigate('/'); window.location.reload() }}>
                        <FaLockOpen />
                        <span>
                            Deconnexion
                        </span>

                    </HStack> :
                        <ButtonGroup variant="ghost-on-accent" spacing="1">
                            {

                                <Link href="/login">
                                    <NavButton label="Login" icon={FiUser} />
                                </Link>
                            }
                        </ButtonGroup>
                    }

                </Stack>
            </Stack>
        </Flex>
    )
}
export default MenuPrincipalBody