import React, { useState, useEffect, useContext, useRef } from 'react';
import { GlobalContext } from '../Context';
import * as yup from "yup";
import { Box, useDisclosure, useSteps } from '@chakra-ui/react';

import { SimpleTableNS } from '../SimpleTable';
import MagicFormNS from '../Task/MagicFormNS';
import ReactCountryFlag from "react-country-flag"
import Datatable from '../Composants/datatable';
import { BsListCheck } from 'react-icons/bs';
import { MdClose, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
const Utilisateur = ({

}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
    const navigate = useNavigate()
    const { service, token, dataUser, logout } = useContext(GlobalContext)
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [item, setItem] = useState({});
    const [roles, setRoles] = useState([]);
    const onGet = () => {
        service.create(token, "crud", {
            method: "GETALL",
            id: "",
            collection: "User",
            body: {}
        }, (resp) => {
            setUtilisateurs(resp?.data?.data)
            // alert(JSON.stringify(resp?.data?.data))

        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
    }
    const columns = [
        {
            title: 'Mail',
            field: 'mail',
            // render: rowData => <><ReactCountryFlag countryCode={rowData?.code}/> {rowData?.nom}</>
        },
        {
            title: 'Nom',
            field: 'nom',
        },
        {
            title: 'Status',
            field: 'status',
        }
        ,
        {
            title: 'Role',
            field: 'role_id',
            lookup: roles.reduce((acc, current, index) => {
                acc[current?._id] = current?.libelle;
                return acc;
            }, {})
        }
    ]
    useEffect(() => {

    }, [utilisateurs]);
    useEffect(() => {
        onGet()
        service.create(token, "crud", {
            method: "GETALL",
            collection: "Role",
            id: "",
            body: {}
        }, (resp) => {
            setRoles(resp?.data?.data)

        },
            err => {
                if (err?.response?.status === 401) {
                    // logout()
                    // navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        )
    }, []);
    return (
        < >
            <Datatable
                icon={<BsListCheck />}
                title={"Liste de secteurs d'activites"}
                data={utilisateurs}
                onGet={onGet}
                columns={columns}
                setData={setUtilisateurs}
                setItem={setItem}
                onToggle={onToggle}
                canAdd={true}
                canEdit={true}
            />
            {/* <SimpleTableNS
                title="Liste des pays membres"
                data={{
                    header: ["Code", "Nom", "Action"],
                    body: pays
                }}
                maxW="600px"
                onOpen={onOpen}
            /> */}
            <FormUtilisateur
                isOpen={isOpen}
                onClose={onClose}
                onGet={onGet}
                item={item}
                roles={roles}


            />

        </>);
}

export default Utilisateur;

const FormUtilisateur = ({
    isOpen,
    onToggle,
    onClose,
    onGet,
    item,
    roles

}) => {
    const funcs = useRef(null);
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: 1,
    })
    let navigate = useNavigate();
    const { service, token, logout } = useContext(GlobalContext)
    const onSubmit = async (data) => {


        const { _id, tableData, url, ...rest } = data

        !_id ? service.create(token, "crud", {
            method: "POST",
            id: "",
            collection: "User",
            body: { ...rest, status: rest?.status === "true" ? true : false }
        }, (resp) => {
            onGet()
            funcs.current.reset(item)
            onClose()
        },
            err => {
                if (err?.response?.status === 401) {
                    logout()
                    navigate('/login')
                }
                console.error(err?.response?.status === 401)
            }
        ) :
            service.create(token, "crud", {
                method: "PUT",
                id: _id,
                collection: "User",
                body: { ...rest, status: rest?.status === "true" ? true : false }
            }, (resp) => {
                onGet()
                funcs.current.reset(item)
                onClose()
            },
                err => {
                    if (err?.response?.status === 401) {
                        logout()
                        navigate('/login')
                    }
                    console.error(err?.response?.status === 401)
                }
            )






    }
    const schemaInit = {
        statut: yup.string().required("Champ status est obligatoire"),
    }
    const steps = [
        {
            title: 'First',
            description: 'Contact Info',
            buttons: [{
                label: "Quitter",
                propriety: {
                    isDisabled: false,
                    w: "full",
                    icon: <MdClose />,
                    onClick: () => {
                        funcs?.current?.reset({})
                        onClose()
                    }
                }
            },

            {
                label: item?._id ? "Changer" : "Sauvegarder",
                propriety: {
                    isDisabled: false,
                    type: "submit",
                    w: "full",
                    px: '10',
                    icon: item?.id ? <MdEdit /> : <MdSave />,
                }
            }]
        },

    ]
    const step = {
        steps: steps,
        activeStep: activeStep,
        orientation: "vertical",
        setActiveStep: setActiveStep
    }


    const champsInit = (watch) => [

        [
            {
                name: "photo",
                label: "Photo de profil",
                type: "image",
                placeholder: "Entrer le mail",
                requiredText: 'false',
                isDisabledText: 'true',
                displayText: "true",
                helpText: "",
                boxSize: "250px"

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            }],[
            {
                name: "mail",
                label: "Mail",
                type: "text",
                placeholder: "Entrer le mail",
                requiredText: 'false',
                isDisabledText: 'true',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
            {
                name: "nom",
                label: "Nom ",
                type: "text",
                placeholder: "Entrer le nom",
                requiredText: 'false',
                isDisabledText: 'false',
                displayText: "true",
                helpText: ""

                // validation: yup.string().required("The title is required"),
                // isDisabled: true,
                // size: "lg"


            },
        
        ],[
            {
                name: "status",
                label: "status",
                type: "radio",
                placeholder: "Selectionner",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                defaultValue: item?.status? "true": "false",
                liste: [
                    {
                        key: "true", value: "Active"
                    },
                    {
                        key: "false", value: "Non active"
                    }
                ],
                helpText: ""


            }],
            [{
                name: "role_id",
                label: "Role",
                type: "select",
                placeholder: "Selectionner",
                requiredText: 'true',
                isDisabledText: 'false',
                displayText: "true",
                defaultValue: item?.role_id,
                liste: roles?.map(role => (
                    {
                        key: role?._id, value: role?.libelle
                    }
                )),
                helpText: ""


            }
        ]
    ]
    useEffect(() => {
        console.log("item", item)
        funcs.current.reset(item)
    }, [item]);
    useEffect(() => {
    }, [funcs?.current?.watch()]);
    return (<>
        <MagicFormNS
            champsInits={champsInit}
            schemaInits={schemaInit}
            step={step}
            isOpen={isOpen}
            onSubmit={onSubmit}
            ref={funcs}
            item={item}
            title="Formulaire "
            size='md'
            view="drawer"
        />
    </>);
}
