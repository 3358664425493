import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  Img,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FaFacebook, FaGithub, FaLinkedin, FaTeamspeak, FaTwitter } from 'react-icons/fa'
import { members } from './data'
import { MdPeople } from 'react-icons/md'

export const Team = () => (
  <Container
     as="section" 
     >
    <HStack my={4}  >

      <Button
        size={{ base: "xs", md: "md" }}
        bg='brand.primary.100'
        leftIcon={<MdPeople />}
        color='white'
        fontSize={{ base: "sm", md: 'md' }}>
        Membres du Conseil Constitutionnel
      </Button>
      <Divider
        borderBottomWidth={1}
      // borderColor='red'
      />
    </HStack>
    <Stack spacing={{ base: '12', md: '16' }}
    shadow='lg' borderBottomWidth={6} borderBottomColor={"brand.secondary"}
    p={10}
    >
      <Stack
        spacing={{ base: '8', md: '10' }}
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
      >
        <Stack spacing="3" w="full">
        </Stack>
        {/* <Stack direction={{ base: 'column-reverse', md: 'row' }} spacing="3">
          <Button variant="secondary" size="xl">
            Contact us
          </Button>
          <Button size="xl" color={"brand.primary.100"}>Join our team</Button>
        </Stack> */}
      </Stack>
      <Stack spacing={{ base: '12', xl: '24' }} direction={{ base: 'column', lg: 'row' }}>
        <Stack spacing="10">
          <Stack spacing="3" maxW="sm" width="full">
            <Stack spacing={{ base: '4', md: '5' }}>
              {/* <Heading size={{ base: 'sm', md: 'md' }}>Meet our team</Heading> */}
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted" maxW="3xl">
              Les membres du Conseil constitutionnel sont au nombre de neuf (9). Ils ont été désignés par le décret N°0037/PT/2024 du 29 janvier 2024 portant désignation des membres du Conseil Constitutionnel.
              </Text>
            </Stack>
          </Stack>

        </Stack>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          columnGap="8"
          rowGap={{ base: '10', lg: '12' }}
          flex="1"
        >
          {members.map((member) => (
            <Stack key={member.name} spacing={{ base: '4', md: '5' }} direction="row">
              <Avatar src={member.image} boxSize={{ base: '16', md: '36' }} />
              <Stack spacing="4">
                <Stack>
                  <Box>
                    <Text fontWeight="medium" fontSize="lg">
                      {member.name}
                    </Text>
                    <Text color="accent">{member.role}</Text>
                  </Box>
                  {/* <Text color="fg.muted">{member.description}</Text> */}
                </Stack>
                <HStack spacing="4" color="fg.subtle">
                  {[FaFacebook, FaLinkedin, FaTwitter].map((item, id) => (
                    <Link href="#" key={id}>
                      <Icon as={item} boxSize="5" />
                    </Link>
                  ))}
                </HStack>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  </Container>
)
