'use client'

import { Stack, Flex, Button, Text, VStack, useBreakpointValue } from '@chakra-ui/react'
import bg from '../../Media/images/bg.jpg'

export default function WithBackgroundImage() {
  return (
    <Flex
      mt={90}
      w={'full'}
      h={'80vh'}
      backgroundImage={
        bg
      }
      backgroundSize={'cover'}
      backgroundPosition={'center center'}

    >
      <VStack
        w={'full'}
        justify={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={'linear(to-tl, blackAlpha.900, transparent)'}

      >
        <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
          <Text
            color={'white'}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
            Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor
          </Text>
          <Stack direction={{ base: 'column', md: 'row' }}
            my={5}
          >
            <Button
              color={"whiteAlpha.900"}
              bg="brand.secondary"
              w={{ base: 'auto', md: 'xs' }}
              mx={5}
              _hover={{
                bgGradient: 'linear(to-r, transparent, brand.primary.100)',
              }}

            >
              Presentation
            </Button>
            <Button
              w={{ base: 'auto', md: 'xs' }}
              mx={5}
              color={"whiteAlpha.900"}
              bg="brand.secondary"
              _hover={{
                bgGradient: 'linear(to-t, transparent, brand.primary.100)',
              }}
            >
              Les actualites
            </Button>
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  )
}