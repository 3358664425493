
import { Box, Badge, SimpleGrid, Image, Link, Text, CardHeader, Flex, IconButton, CardBody, CardFooter, Card, Avatar, Heading } from '@chakra-ui/react';
import { Button, Center, Container, Divider, HStack, Stack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Tooltip, Wrap, WrapItem, useBreakpointValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { BsInboxFill, BsThreeDotsVertical } from 'react-icons/bs';
import { FaHandPointRight, FaNewspaper, FaPlusCircle } from 'react-icons/fa';
import { SimpleSlider } from '../partenaires';
import { BiChat, BiLike, BiShare } from 'react-icons/bi';

const dataList = [
  {
    id: 1,
    title: 'PROGRAMME DES ELECTIONS LEGISLATIVES, COMMUNALES ET PROVINCIALES',
    authorName: 'administrateur',
    img: "https://images.unsplash.com/photo-1504598578017-40d9b776f1bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 2,
    title: 'PROCLAMATION DES RESULTATS PROVISOIRES DE L’ELECTION PRESIDENTIELLE',
    authorName: 'administrateur',
    img: "https://images.unsplash.com/photo-1650499310282-cda4d957f5be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 3,
    title: 'ATELIER DE FORMATION DES FORMATEURS DES MEMBRES DU BUREAU DE VOTE',
    authorName: 'Administrateur',
    img: "https://images.unsplash.com/photo-1473594659356-a404044aa2c2?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 6,
    title: 'PROGRAMME DES ELECTIONS LEGISLATIVES, COMMUNALES ET PROVINCIALES',
    authorName: 'administrateur',
    img: "https://images.unsplash.com/photo-1504598578017-40d9b776f1bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 5,
    title: 'PROCLAMATION DES RESULTATS PROVISOIRES DE L’ELECTION PRESIDENTIELLE',
    authorName: 'administrateur',
    img: "https://images.unsplash.com/photo-1650499310282-cda4d957f5be?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  },
  {
    id: 4,
    title: 'ATELIER DE FORMATION DES FORMATEURS DES MEMBRES DU BUREAU DE VOTE',
    authorName: 'Administrateur',
    img: "https://images.unsplash.com/photo-1473594659356-a404044aa2c2?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    content: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
  }
];

const Articles = () => {
  const actualites = dataList.map((blog) => {
    return (
      <Box position="relative" key={blog.id} px={2} as="section">

        <Card w='auto'>
          <CardHeader>
            <Flex spacing='4'>
              <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' />

                <Box>
                  <Text size='sm'>Segun Adebayo</Text>
                  <Text>Administrator</Text>
                </Box>
              </Flex>
              <IconButton
                variant='ghost'
                colorScheme='gray'
                aria-label='See menu'
                icon={<BsThreeDotsVertical />}
              />
            </Flex>
          </CardHeader>
          <CardBody>
            <Box
              fontWeight="semibold"
              as="h2"
              letterSpacing="wide"
              textTransform="uppercase"
              ml="2"
            >
              {blog.title}
            </Box>
            <Text>
              {blog?.content}
            </Text>
          </CardBody>
          <Image
            objectFit='cover'
            src={blog?.img}
            alt='Chakra UI'
          />

          <CardFooter
            justify='space-between'
            flexWrap='wrap'
            sx={{
              '& > button': {
                minW: '100px',
              },
            }}
          >
            <Button flex='1' variant='ghost' leftIcon={<BiLike />}>
              Like
            </Button>
            <Button flex='1' variant='ghost' leftIcon={<BiChat />}>
              Comment
            </Button>
            <Button flex='1' variant='ghost' leftIcon={<BiShare />}>
              Share
            </Button>
          </CardFooter>
        </Card>

      </Box>
    );
  })
  return (
    <Container maxW={{ base: 'auto', md: 'full' }} p={{ base: 5, md: 10 }}>
      <SimpleSlider items={actualites} settingsIn={{
        dots: true,
      }} />
    </Container>
  );
};


const ArticleBlog = ({

}) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const adminMenu = [
    {
      label: "Dernières informations",
      key: "infos",
      children: [],
      tooltip: "Dernières informations",
      show: true,
      icon: <FaNewspaper />,
      component: <Articles />
    }
  ]
  const [selectedMenu, setselectedMenu] = useState(adminMenu[0]);

  return (
    <>
      <HStack my={4} w={{ base: 'auto', md: 'full' }} as="section">

        <Button
          my={2}
          px={8}
          w="auto"
          leftIcon={<FaNewspaper />}
          color={"whiteAlpha.900"}
          bg="brand.secondary"
          variant="solid"
        >
          Dernières actualités
        </Button>
        <Divider
          borderBottomWidth={1}
        // borderColor='red'
        />
      </HStack>
      <Stack
        // spacing="24"
        direction="column"
        shouldWrapChildren
        shadow='lg'
        borderBottomWidth={4}
        borderBottomColor={"brand.primary.100"}
        w="full"


      >
        <Articles />



      </Stack>
    </>

  );
}



export function Title({
  title,
  ...props
}) {
  return (
    <Center>
      <HStack>
        <Divider />
        <Button variant="secondary" {...props} >
          {title}
        </Button>
        <Divider />
      </HStack>
    </Center>
  )
}
export default ArticleBlog