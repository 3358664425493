import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Field } from './Field';
import { Box, Button, Flex, Stack } from '@chakra-ui/react';
function Formulaire(props) {
    const { champs, handleSubmit, onSubmit, ...rest } = props
    // const { register, handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    //     resolver: yupResolver(schema)
    // });


    return (
        <Box

        >
            {champs?.map((liste) => {
                return (
                    <Stack spacing="3" direction={{ base: 'column', md: 'row' }}  mx={"auto"}>
                        {liste?.map((item, index) => {
                            return (

                                <Field
                                    {...item}
                                    {...rest}
                                />
                            )
                        })}
                    </Stack>)
            })
            }
        </Box>

    )
}

export default Formulaire