import { FormControl, FormErrorMessage, FormHelperText, FormLabel,  Textarea } from '@chakra-ui/react'
import React from 'react'

export const TextAreaForm = React.forwardRef((props, ref) => {
    const {name, label, helpText,type,register, placeholder, required, errors, display}=props

    return (
        <>
            <FormControl mt={2} isInvalid={errors[name]} display={display} w={{base: "full" ,md:"full"}}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required? `${label}*` :`${label}`  }
                </FormLabel>
                <Textarea  colorScheme='yellow' id={name} type={type} {...register(name)} name={name} placeholder={placeholder} />
                <FormErrorMessage>
                    { errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
TextAreaForm.displayName = 'TextAreaForm'