import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Input,
  Stack,
  Text,
  Flex,
  useBreakpointValue
} from '@chakra-ui/react'
import { Link } from "react-router-dom";
import * as React from 'react'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
import LogoSA  from '../../Media/images/logo_cc.png'
import { FooterApp } from './footer';

const Footer = () => {
  const isDesktop = useBreakpointValue({
      base: false,
      lg: true,
  })
  return (
    //   <Box bg="brand.primary.100" color="white" minW="100%"
    //       // position="absolute"
    //       left={0}
    //       bottom={0}

    //   >
    //       <Container 
    //       as="footer" 
    //       role="contentinfo" 
    //       minWidth='80%' 
    //       alignItems='center'>
    //           <Stack
    //               pt="8"
    //               pb="2"
    //               justify="space-between"
    //               direction={{
    //                   base: 'column-reverse',
    //                   md: 'row',
    //               }}
    //               align="center"
    //           >
    //               {/* <LogoSA/> */}
    //               <Flex align="center">
    //                   <Text color="on-accent-subtle" fontSize="sm">
    //                       &copy; {new Date().getFullYear()} Naibi SSO. All rights reserved.
    //                   </Text>

    //               </Flex>
    //               <ButtonGroup variant="ghost-on-accent">
    //                   <IconButton
    //                       as="a"
    //                       href="#"
    //                       aria-label="LinkedIn"
    //                       icon={<FaLinkedin fontSize="1.25rem" />}
    //                   />
    //                   <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />
    //                   <IconButton
    //                       as="a"
    //                       href="#"
    //                       aria-label="Twitter"
    //                       icon={<FaTwitter fontSize="1.25rem" />}
    //                   />
    //               </ButtonGroup>
    //           </Stack>
    //       </Container>
    //   </Box>
    <FooterApp/>
      )
}
export default Footer