import { useState, useEffect, useContext, useMemo } from 'react'
import {
    Progress,
    Box,
    ButtonGroup,
    Button,
    Heading,
    Flex,
    FormControl,
    GridItem,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    InputLeftAddon,
    InputGroup,
    Textarea,
    FormHelperText,
    InputRightElement,
    FormErrorMessage,
    PinInput,
    PinInputField,
    HStack,
    Spacer,
    Stack,
    Center,
    Text
} from '@chakra-ui/react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from '@chakra-ui/react'
import { GlobalContext } from '../Context';
import { LogoSAVert } from '../Header/LogoSA';
import { useNavigate } from 'react-router-dom';

const FormDecision = ({
    props
}) => {
    const { login, retrieve } = useContext(GlobalContext)
    const [isLogin, setIsLogin] = useState(false)
    let navigate = useNavigate();
    const schema = yup.object().shape({
        mail: yup.string().email("L'adresse mail n'est pas valide").required("Le mail est obligatoire"),
        motpasse: yup.string().min(8, "Minimum 8 caractere").required("Le mot de passe est obligatoire"),
        // confirm_password: yup.string().label('confirmation mot de passe').required().oneOf([yup.ref('motpasse'), null], 'Passwords must match'),
    });

    const onSubmit = async (data) => {

        const resp = login(data)
        if (resp) {
            setIsLogin(!isLogin)
            navigate('/')

        }


    }
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(schema)
    });
    useEffect(() => {
        if (isLogin) {
            retrieve()
        }

    }, [isLogin])

    return (
        <>
            <Center bg="brand.primary.100">

                <Heading
                    // as="h1"
                    size={{ base: "sm", md: "sm" }}
                    color={"brand.secondary"}
                    mt="5"
                    fontWeight="extrabold"
                    letterSpacing="tight"
                    textAlign='center'
                >
                    Rechercher une decision
                </Heading>


            </Center>
            <Stack spacing="8" {...props} w='lg' as="section"
                borderColor="brand.primary.100"
                shadow='lg'
                px={5}
            >

                <Stack spacing="6" fontSize={"2xl"}>
                    <form
                    >

                        <FormControl mt="2%" isInvalid={errors?.mail} w='xs' fontSize={"lg"}>
                            <FormLabel htmlFor="motcle" fontWeight={'normal'}>
                                Mot Clés*
                            </FormLabel>
                            <Input id="motcle" type="text" {...register("motcle")} name="motcle" placeholder="Entrer les mots clés" />
                            <FormErrorMessage>
                                {errors?.motcle && errors?.motcle?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt="2%" isInvalid={errors?.reference} w='xs'>
                            <FormLabel htmlFor="reference" fontWeight={'normal'}>
                            Reférence de Décision
                            </FormLabel>
                            <Input id="reference" type="text" {...register("reference")} name="reference" placeholder="Entrer la reférence de Décision" />
                            <FormErrorMessage>
                                {errors?.text && errors?.reference?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt="2%" isInvalid={errors?.reference} w='xs'>
                            <FormLabel htmlFor="requerant" fontWeight={'normal'}>
                            Requérant
                            </FormLabel>
                            <Input id="requerant" type="text" {...register("requerant")} name="requerant" placeholder="Entrez le nom du requérant" />
                            <FormErrorMessage>
                                {errors?.reference && errors?.reference?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt="2%" isInvalid={errors?.modatedebuttpasse} w='xs'>
                            <FormLabel htmlFor="datedebut" fontWeight={'normal'}>
                            Entre le:
                            </FormLabel>
                            <Input id="datedebut" type="date" {...register("datedebut")} name="datedebut" placeholder="Entrer la reférence de Décision" />
                            <FormErrorMessage>
                                {errors?.datedebut && errors?.datedebut?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl mt="2%" isInvalid={errors?.datefin} w='xs'>
                            <FormLabel htmlFor="datedebut" fontWeight={'normal'}>
                            Et le:
                            </FormLabel>
                            <Input id="datefin" type="date" {...register("datefin")} name="datefin" placeholder="Entrer la reférence de Décision" />
                            <FormErrorMessage>
                                {errors?.datefin && errors?.datefin?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <ButtonGroup mt="5%" w="md">
                            <Flex w="100%" justifyContent="space-between">
                                {<Button
                                    w="xs"
                                    color={"whiteAlpha.900"}
                                    bg="brand.secondary"
                                    variant="solid"
                                    onClick={handleSubmit(onSubmit)}
                                    mb={4}
                                >
                                    Rechercher
                                </Button>

                                }
                                <Box></Box>
                            </Flex>
                        </ButtonGroup>

                    </form>
                </Stack>

            </Stack>
        </>
    )
}

export default FormDecision