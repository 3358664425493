import { Box, Button, Collapse, HStack, Icon, Link, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { FiChevronDown, FiFile } from 'react-icons/fi'
import { SidebarButton } from './SidebarButton'
import { IconBase } from 'react-icons'

export const DocumentCollapse = ({ item, openUrl }) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Box>
      <Button
        variant="tertiary.accent"
        onClick={onToggle}
        justifyContent="space-between"
        width="full"
      >
        <HStack spacing="3">
          <Icon as={item?.icon} />
          <Text as="span">{item?.label}</Text>
        </HStack>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={!isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="8" py="1">
          {item?.children.map((child) => (
            <SidebarButton 
            leftIcon={<Icon as={child?.icon} 
            />}><Link href={child?.url}> {child?.label} </Link></SidebarButton>
          ))}
        </Stack>
      </Collapse>
    </Box>
  )
}

export const PopoverIcon = (props) => {
  const iconStyles = {
    transform: props.isOpen ? 'rotate(-180deg)' : undefined,
    transition: 'transform 0.2s',
    transformOrigin: 'center',
  }
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />
}
