import React from 'react';
import {
  ChakraProvider,
  extendTheme,
  theme as baseTheme
} from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import RouteApplication from './Route';
import Header from './Pages/Header';
import { GlobalProvider } from './Pages/Context';
import Footer from './Pages/Footer';
import i18n  from './lang/i18n';
import { I18nextProvider } from 'react-i18next';
import { PrimeReactProvider } from 'primereact/api';

const colors = {
  brand: {
    primary: {
      100:'#25295a',
    },
    secondary: "#ffbb01", 
    rouge: {
      900: "#ffbb01"
    },
    vert: {
      900: '#9C6615',
    },
    bleu: {
      900: '#5F0F40'
    },
    jaune: {
      900: '#FF9800'
    },
    scheme: "yellow"
  },
  fonts: {
    heading: "Poppins",
    body: "poppins",
  },
}
const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
}

// 3. Extend the theme
// const theme = extendTheme({ breakpoints })
const theme = extendTheme({ colors, breakpoints }, baseTheme, proTheme)
function App() {
  return (
    <ChakraProvider theme={theme}>
       <PrimeReactProvider>
      <GlobalProvider > 
        <I18nextProvider i18n={i18n}>
          <RouteApplication />
        </I18nextProvider>

      </GlobalProvider>
      </PrimeReactProvider>
    </ChakraProvider>
  );
}

export default App;
