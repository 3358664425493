import APIClient from './CLient';

const PATH_IMAGE = 'uploadImages';
const PATH_FILE = 'files';

export default class UploadService {

    constructor(token=null) {
        this.client = APIClient.instance(token);
    }
    uploadImage(data, successCallback, errorCallback) {
        this.client.postFile(null,PATH_IMAGE, data, successCallback, errorCallback);
    }
    uploadFile(data, successCallback, errorCallback) {
        this.client.postFile(null,PATH_FILE, data, successCallback, errorCallback);
    }

    getImage(externalId, successCallback, errorCallback) {
        this.client.get(`${PATH_IMAGE}/${externalId}`, successCallback, errorCallback);
    }
    deleteImage(externalId, successCallback, errorCallback) {
        this.client.delete(`${PATH_IMAGE}/${externalId}`, successCallback, errorCallback);
    }
    deleteFile(externalId, successCallback, errorCallback) {
        this.client.delete(`${PATH_FILE}/${externalId}`, successCallback, errorCallback);
    }
    
}