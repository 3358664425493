import { Button, Center, Container, Divider, HStack, Stack, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Tooltip, Wrap, WrapItem, useBreakpointValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

import Utilisateur from './Chambre';
import { FaTools } from 'react-icons/fa';
import { BiUserCircle } from 'react-icons/bi';
import { BsShieldLock } from 'react-icons/bs';
import TypeChambre from './TypeChambre';
import Chambre from './Chambre';

const HerbergementAdministration = ({

}) => {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    const adminMenu=[
        {
            label: "Categorie de chambre",
            key: "categorie",
            children: [],
            tooltip: "Gestion des Type de chambres",
            show: true,
            icon: <BsShieldLock />,
            component: <TypeChambre />
        },
        {
            label: "Chambre",
            key: "chambre",
            show: true,
            icon: <BiUserCircle />,
            tooltip: "Administration des chambres",
            component: <Chambre />

        },
    ]
    const [selectedMenu, setselectedMenu] = useState(adminMenu[0]);

    return (
        <Container >

            <HStack py={10} w={"100%"}>
                <Divider />
                <Wrap spacing='10px' justify='center' w={"full"}>
                   {adminMenu?.map(menu=>(<WrapItem>
                        <Tooltip label={menu?.tooltip}>
                            <Button
                                size={{ base: "xs", md: "md" }}
                                onClick={()=>setselectedMenu(menu)}
                                variant="secondary" leftIcon={menu?.icon} color={selectedMenu?.key===menu?.key? "white" :'brand.secondary' } bg={selectedMenu?.key===menu?.key? "brand.secondary" :'white'} fontSize={{ base: "sm", md: 'md' }}>
                                    {isDesktop && menu?.label}
                            </Button>
                        </Tooltip>
                    </WrapItem>)) }
                </Wrap>
                <Divider />
            </HStack>
            <Stack spacing="24" direction="column" shouldWrapChildren
                shadow='lg' borderBottomWidth={4} borderBottomColor={"brand.secondary"}
                w="auto"

            >
                {selectedMenu?.component}



            </Stack>

        </Container>);
}

export default HerbergementAdministration;


export function Title({
    title,
    ...props
}) {
    return (
        <Center>
            <HStack>
                <Divider />
                <Button variant="secondary" {...props} >
                    {title}
                </Button>
                <Divider />
            </HStack>
        </Center>
    )
}
