import { Button, HStack, Icon, Text } from '@chakra-ui/react'
import React from 'react'

export const NavButton = (props) => {
  const { icon, label, ...buttonProps } = props
  return (
    <Button variant="ghost-on-accent" justifyContent="start" {...buttonProps} size={'md'}>
      <HStack spacing="3" fontSize={{base: "sm", md:"sm"}}>
        <Icon as={icon} boxSize="5" color="on-accent-subtle" />
        <Text >{label}</Text>
      </HStack>
    </Button>
  )
}
