import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Select } from '@chakra-ui/react'
import React from 'react'

export const SelectForm = React.forwardRef((props, ref) => {
    const { name, label, helpText,liste, defaultValue, placeholder, required, errors, control, Controller, display, ...rest } = props

    return (
        <>
            <FormControl mt={5} isInvalid={errors[name]} display={display} w={{base: "full" ,md:"full"}}>
                <FormLabel htmlFor={name} fontWeight={'normal'} textColor="brand.primary.100">
                    {required ? `${label}*` : `${label}`}
                </FormLabel>

                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    render={({ field: { onChange, value, ref } }) => (
                        <Select
                            placeholder={placeholder}
                            onChange={onChange}
                            value={value}
                            focusBorderColor="brand.primary.100"
                            {...rest} 

                        >
                            {liste.map((item, index) => {
                                return (
                                    <option id={index} value={item.key} >{item.value}</option>
                                )
                            })
                            }
                        </Select>
                    )}
                />
               
                <FormErrorMessage>
                    { errors[name]?.message}
                </FormErrorMessage>
                <FormHelperText >{helpText}</FormHelperText>
            </FormControl>
        </>
    )
})
SelectForm.displayName = 'SelectForm'