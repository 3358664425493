import {
  Box,
  Button,
  Heading,
  Img,
  Stack,
  Text,
  Accordion,
  useColorModeValue as mode,
  Flex,
  Spacer,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiSend } from 'react-icons/fi'
import { HiPlay } from 'react-icons/hi'
import { Link } from "react-router-dom";
import bg2 from './../../Media/images/PR_CC.jpeg'
import { AccordionMotion } from './Accordion';
import { useTranslation } from 'react-i18next';
import { FacebookShare } from 'react-share-kit'
export function Apropos() {
  const [expanded, setExpanded] = React.useState(0);
  const { t } = useTranslation()

  return (

        <Stack
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '3rem', lg: '2rem' }}
          mt="8"
          align={{ lg: 'center' }}
          justify="space-between"
        >
          <Box pos="relative" w={{ base: 'full', lg: '40%' }} h={{ base: 'auto', lg: '100%' }}>
            <Img
              w="full"
              pos="relative"
              zIndex="1"
              h={{ lg: 'full' }}
              objectFit="cover"
              src={bg2}
              alt="Screening talent"
              borderRadius={"md"}
            />
            <Box
              pos="absolute"
              w="100%"
              h="100%"
              top="-6"
              left="-4"
              bg={'brand.secondary'}
              borderTopRadius={"md"}
            />
          </Box>
          <Box flex="1" maxW={{ lg: '55%' }}>
            <Heading
              // as="h1"
              size={{ base: "sm", md: "sm" }}
              color={"brand.secondary"}
              mt="5"
              fontWeight="extrabold"
              letterSpacing="tight"
              textAlign='center'
            >
              Mot du President du Conseil Constitutionnel.
            </Heading>
            <Text color={mode('white', 'white')} mt="4" fontWeight="medium" fontSize={"md"}>
              {/* Cette plateforme digitale vise à faciliter la mise en relation des organisations de la société civile et des partenaires techniques et financiers et à recenser les opportunités de financements pour les organisations de la société civile au Sahel.  */}
              {`Bienvenue sur le site officiel du Conseil Constitutionnel. C'est avec grand plaisir que nous vous accueillons dans cet espace dédié à la défense de l'État de droit et à la protection des principes fondamentaux de notre Constitution. Explorez nos ressources pour mieux comprendre notre mission et notre engagement envers la démocratie et les valeurs constitutionnelles qui guident notre nation.`}

            </Text>
            <Flex w="100%" justifyContent="space-between">
            <Spacer />
            {<Button
            my={2}
              w="md"
              color={"whiteAlpha.900"}
              bg="brand.secondary"
              variant="solid"
            >
              Presentation du conseil
            </Button>

            }
            <Box></Box>
          </Flex>
            {/* <Accordion

            >
              {activites.map((item) => (
                <AccordionMotion i={item?.id} expanded={expanded} setExpanded={setExpanded} {...item} />
              ))}
            </Accordion> */}
          </Box>
         
        </Stack>
     
  )
}