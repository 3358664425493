import {
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Container,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    IconButton,
    useBreakpointValue,
    useDisclosure,
    Spacer,
    Heading,
    useColorMode,
    Stack,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Image,
    Divider,
} from '@chakra-ui/react'
import * as React from 'react'
import {
    FiBarChart2,
    FiBookmark,
    FiCheckSquare,
    FiHome,
    FiHelpCircle, FiSearch, FiSettings, FiUser
} from 'react-icons/fi'
import LogoSA from './LogoSA'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from '../Context'
import { useContext, useEffect } from 'react'
import {

    SunIcon,
    MoonIcon,
    ChevronDownIcon
} from '@chakra-ui/icons';
import { NavButton } from './NavButton'
import { FaBook, FaBullhorn, FaCpanel, FaDirections, FaEnvelope, FaFileInvoiceDollar, FaGlobeEurope, FaIdCard, FaLockOpen, FaNewspaper, FaTools, FaUser, FaUserCog, FaUserFriends, FaUsers, FaUsersCog, FaWpforms } from 'react-icons/fa'
import { UserProfile } from './UserProfile'
import Profil from '../Profil'
import { MdBuild } from 'react-icons/md'
import MenuPrincipalBody from './menu'
import { UilRestaurant, UilGlassMartiniAlt, UilHouseUser } from '@iconscout/react-unicons'
const Navbar = ({ menuData }) => {
    const isDesktop = useBreakpointValue({
        base: false,
        lg: true,
    })
    const { colorMode, toggleColorMode } = useColorMode();
    const navigate = useNavigate()
    const { isOpen: isOpenProfil, onClose: onCloseProfil, onToggle: onToggleProfil } = useDisclosure()
    const { isOpen, onClose, onToggle } = useDisclosure()
    const { login, dataUser, token, logout, retrieve } = useContext(GlobalContext)
    useEffect(() => {
        retrieve()
    }, [])
    return (
        <
            >

            <Box
                as="header" position="fixed"
                top="0"
                w="100%"
                // bgGradient='linear(to-tl, blackAlpha.600, black)'
                bg='blackAlpha.900'
                zIndex={3}

                // bgGradient={'linear(to-r, brand.primary.100, brand.secondary)'}
                color="white"
                fontSize={{ base: "sm", md: "md" }}
                mb={2}
            // borderBottomWidth={"3px"}
            // borderColor="brand.primary.100"
            >
                <HStack
                    minWidth='max-content' alignItems='center'
                    mb={2}
                    justifyContent="space-between"
                >
                    <Box
                        w="45%"
                        borderBottomWidth={2}
                       borderColor="brand.primary.100"
                        align="center"
                        pb={1}
                    >
                        <Text 
                        fontWeight={500}
                        lineHeight={1.2}
                        fontSize={useBreakpointValue({ base: 'sm', md: 'xl' })}
                        > 
                            Conseil Constitutionnel
                        </Text>
                        </Box>

                    <Button
                        size={{ base: "xs", md: "lg" }}
                        // variant="secondary"
                        
                         bg='brand.secondary'
                    >
                        <Link to="/"><LogoSA /></Link>
                    </Button>

                    <Box
                        w="45%"
                        borderBottomWidth={2}
                        borderColor='red'
                        align="center"
                        pb={1}
                    >
                        <Text 
                        fontWeight={500}
                        lineHeight={1.2}
                        fontSize={useBreakpointValue({ base: 'sm', md: 'xl' })}
                        > 
                            Republique du Tchad
                        </Text>
                        </Box>
                </HStack>

                <Flex minWidth='max-content' alignItems='center'
                    px={8}
                >
                    {/* <Logo /> */}

                    <Spacer />
                    {isDesktop ? (
                        <Flex w="100%" justifyContent="space-between" pl="20">
                            <Box></Box>
                            <HStack spacing="1"
                            // color="brand.secondary"

                            >
                                {!isOpen && !isDesktop && <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />}
                                <Link href="/">
                                    <NavButton label="Accueil" icon={FiHome}
                                        _hover={{
                                            // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                            bg: "brand.secondary",
                                            color: "white"
                                        }}
                                    />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="Le conseil" icon={FaUserFriends} aria-current="page"
                                        _hover={{
                                            // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                            bg: "brand.secondary",
                                            color: "white"
                                        }} />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="La saisine" icon={FaWpforms}
                                        _hover={{
                                            // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                            bg: "brand.secondary",
                                            color: "white"
                                        }}
                                    />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="La constitution" icon={FaBook}
                                        _hover={{
                                            // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                            bg: "brand.secondary",
                                            color: "white"
                                        }} />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="Les décisions" icon={FaEnvelope}
                                        _hover={{
                                            // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                            bg: "brand.secondary",
                                            color: "white"
                                        }} />
                                </Link>
                                <Link to="/#">
                                    <NavButton label="Les actualités" icon={FaNewspaper}
                                        _hover={{
                                            // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                            bg: "brand.secondary",
                                            color: "white"
                                        }} />
                                </Link>
                            </HStack>

                            <HStack
                                // bgGradient="linear(to-b, brand.primary.100, brand.secondary)"
                                // borderRadius={2}
                                justify={"space-between"}
                            >

                                <ButtonGroup variant="ghost-on-accent" spacing="1">
                                    {!token &&

                                        <Link to="/login">
                                            <NavButton label="Connexion" icon={FiUser}
                                                _hover={{
                                                    // bgGradient: 'linear(to-r, transparent, brand.primary.100)',
                                                    bg: "brand.secondary",
                                                    color: "white"
                                                }}
                                            />
                                        </Link>
                                    }


                                    {token &&

                                        <Button
                                            fontSize={'sm'}
                                            fontWeight={600}
                                            color={'white'}
                                            onClick={onToggle}
                                            _hover={{
                                                // bg: "brand.primary.100",
                                                bg: "brand.secondary",
                                                color: "white"
                                            }}
                                        >
                                            <HStack>
                                                <Avatar
                                                    size='xs'
                                                    name={dataUser?.user?.photo_full ? `${dataUser?.user?.photo_full?.alt}` : dataUser?.user?.mail}
                                                    src={`${dataUser?.user?.photo_full?.url}`}
                                                    mr='12px'
                                                />
                                                <span>{dataUser?.user?.nom ? `${dataUser?.user?.nom}` : dataUser?.user?.mail}</span>


                                            </HStack>

                                        </Button>

                                    }
                                    <Button onClick={toggleColorMode}>
                                        {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                                    </Button>
                                </ButtonGroup>


                            </HStack>
                        </Flex>
                    ) : (
                        <>
                            {!isOpen && <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />}

                        </>
                    )}
                    <MenuPrincipal isOpen={isOpen} onClose={onClose} dataUser={dataUser}
                        navigate={navigate}
                        logout={logout}
                        menuData={menuData}
                    />

                </Flex>

            </Box>

        </ >
    )
}
const MenuPrincipal = ({
    isOpen,
    onClose,
    dataUser,
    logout,
    navigate,
    menuData
}) => {
    return (
        <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            // isFullHeight
            preserveScrollBarGap
            // Only disabled for showcase
            maxW={{ base: 'full', sm: 'sm' }}
            trapFocus={false}

        >
            <DrawerOverlay />
            <DrawerContent
                bgGradient={'linear(to-r, brand.primary.100, brand.secondary)'}

            >
                <MenuPrincipalBody menuData={menuData} user={dataUser?.user} logout={logout} />
                {/* <Sidebar dataUser={dataUser} logout={logout} navigate={navigate}/> */}
            </DrawerContent>

        </Drawer>
    )
}
export default Navbar